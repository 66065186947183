.LoginSubmission-window{
    text-align: white;
}

/* LoginSubmission.css */

.registered-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: zoomOut 2s infinite;
}

.registered-icon {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

.registered-text {
    color: #07C007;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
}

/* Infinite zoom-out animation */
@keyframes zoomOut {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
